module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-modal-routing/gatsby-browser.js'),
      options: {"plugins":[],"modalProps":{"style":{"overlay":{"backgroundColor":"rgba(0, 0, 0, 0.5)"},"content":{"position":"absolute","border":"none","background":"none","padding":0,"top":0,"bottom":0,"right":0,"left":0,"overflow":"auto","WebkitOverflowScrolling":"touch"}}}},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Letenky kamkoliv","short_name":"Letenky kamkoliv","start_url":"/","background_color":"#fff","theme_color":"#4299e1","display":"standalone","icon":"src/images/icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"9a52809437a402eab4b36d0f1d86562b"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-K9NC1W554Y"],"gtagConfig":{"optimize_id":"OPT_CONTAINER_ID","anonymize_ip":true,"cookie_expires":0},"pluginConfig":{"head":false,"respectDNT":false,"exclude":["/preview/**","/do-not-track/me/too/"]}},
    }]
